.auction-navigation-container {
  background-image: url('../../Assets/AuctionExample.png');
  background-size: cover;
  background-position: center;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
  background-color: var(--primary-background-color);
  color: var(--blank-color);
  border-bottom: solid var(--secondary-background-color);
}

@media (min-aspect-ratio: 1/1) {
  .auction-navigation-container {
    background-position: 0px -100px;
  }
}

.auction-navigation-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(210, 210, 210, 0.913);
  /* Dark overlay */
  border: none;
  z-index: 1;
}

.auction-navigation-container-content {
  margin: 50px 20px 50px 20px;
  position: relative;
  z-index: 2;
}

.auction-navigation-container-content h2 {
  color: black;
  font-size: 2rem;
  font-weight: bold;
}

.auction-navigation-container-content p {
  color: black;
  font-size: 0.9rem;
  margin-top: 10px;
  padding-right: 20%;
  padding-left: 20%;
}

@media (max-aspect-ratio: 1/1) {
  .auction-navigation-container-content p {
    padding-right: 10px;
    padding-left: 10px;
  }

  /* .auction-navigation-container-content {
    margin: 50px 10px 50px 10px;
  } */
}

.auction-navigation-links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.auction-navigation-item {
  font-size: 1.1rem;
  display: block;
  max-width: 800px;
  min-width: 50%;
  height: auto;
  background-image: linear-gradient(to top right, var(--primary-background-color), var(--secondary-background-color));
  /* Button background color */
  color: var(--blank-color);
  /* Text color */
  text-align: center;
  padding: 15px;
  /* Adjust padding to suit the content */
  border-radius: 2px;
  /* Rounded corners */
  text-decoration: none;
  /* Remove underline */
  transition: transform 0.1s ease;
  margin: 10px;
}

.auction-navigation-item:hover {
  transform: scale(1.015);
}

.auction-navigation-item h5 {
  margin: 0;
  font-size: 1.1rem;
  /* Adjust font size as needed */
}

.auction-navigation-item p {
  color: var(--blank-color);
  margin: 5px 0 0;
  /* Adjust margin to reduce space between texts */
  font-size: 0.7rem;
  /* Adjust font size as needed */
}

.all-auction-navigation-item {
  display: block;
  width: 40%;
  max-width: 200px;
  height: auto;
  background-image: linear-gradient(to top right, var(--primary-background-color), var(--secondary-background-color));
  /* background-color: var(--secondary-background-color); */
  /* Button background color */
  color: var(--blank-color);
  /* Text color */
  text-align: center;
  padding: 10px;
  /* Adjust padding to suit the content */
  border-radius: 2px;
  /* Rounded corners */
  text-decoration: none;
  /* Remove underline */
  transition: transform 0.1s ease;
  margin: 10px;
  font-size: 0.8rem;
}

.all-auction-navigation-item:hover {
  transform: scale(1.015);
}

@media (max-aspect-ratio: 1/1) {
  .auction-navigation-item {
    max-width: none;
    padding-right: 12%;
    padding-left: 12%;
  }

  .auction-navigation-item:hover {
    transform: scale(1);
  }

  .all-auction-navigation-item:hover {
    transform: scale(1);
  }
}