/* Container for password fields and toggle button */
.password-container {
  display: flex;
  align-items: center;
}

/* Style the toggle password button */
.toggle-password {
  margin-left: 10px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Style the register button */
.register-button {
  width: 100%;
  padding: 10px;
  background-color: var(--primary-background-color);
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

/* Hover effect for the register button */
.register-button:hover {
  background-color: var(--button-hover-color);
}

/* Style the error message */
.error-message {
  color: var(--danger-color);
  margin-bottom: 20px;
}

/* Reset some default margins and padding */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Square Badge */
.square-badge-container {
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: center;
}

.square-badge {
  width: 100px;
  margin-right: 10px;
}

.square-badge-container p {
  margin-top: 10px;
  justify-content: center;
}

/* Tooltip Styles */
.tooltip-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-bottom: 40px;
  color: gray;
  text-decoration: underline;
}

.tooltip-text {
  font-size: 14px;
  visibility: hidden;
  width: 200px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  /* Position the tooltip above the text */
  left: 50%;
  margin-left: -100px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.credit-card-form buttonProps {
  color: var(--primary-background-color);
}

/* Modal styles */
.terms-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.terms-modal-content {
  display: flex;
  flex-direction: row;
  background-color: var(--blank-color);
  margin: auto;
  border: 1px solid #888;
  width: 90%;
  max-width: 1000px;
  text-align: left;
  height: 80vh;
  border-radius: 5px;
}

.terms-modal-close-container {
  align-content: right;
  align-items: right;
  text-align: right;
  position: relative;
  margin-right: 10px;
}

.terms-modal-text li {
  direction: ltr;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/* Terms and Conditions Checkbox */
.form-group.terms {
  margin-top: 20px;
  display: flex;
  justify-content: left;
  align-content: center;
  align-items: center;
  height: 1lh;
}

.form-group.terms input {
  margin-right: 5px;
  height: 15px;
  width: 15px;
}

.terms-link {
  color: var(--primary-background-color);
  text-decoration: underline;
  cursor: pointer;
}