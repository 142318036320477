body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: var(--blank-color);
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  text-align: center;
  background-color: var(--blank-color);
  font-family: var(--font-family);
}

.navbar {
  background-color: #f5f5f5;
  padding: 10px 0;
}

.footer {
  background-color: #f5f5f5;
  padding: 20px 0;
  margin-top: auto;
}

.main-content {
  flex: 1;
}

:root {
  --primary-background-color: #075571;
  --secondary-background-color: #228195;
  --foreground-color: #85afda;
  --highlight-color: #c3e8e0;
  --blank-color: #f7f7f7;
  --danger-color: #e63b60;
  --button-hover-color: #fcadb0;
  --inventory-item-container-color: #ccd1d3;
  --strong-highlight-color: #04c162;
  --item-view-foreground-color: #bce3fa;

  --font-family: "Helvetica";
}