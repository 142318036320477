/* Ensure the auth box is positioned relative to contain the loading bar */
.auth-box {
  position: relative;
  background: white;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 40px;
  max-width: 400px;
  width: 100%;
  text-align: center;
  overflow: hidden;
  font-family: var(--font-family);
}

/* Loading bar styles */
.loading-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: var(--secondary-background-color);
  border-radius: 5px 5px 0 0;
  animation: loading 2s forwards;
}

@keyframes loading {
  0% {
    left: 0%;
    width: 5%;
  }

  50% {
    left: 0%;
    width: 100%;
  }

  100% {
    left: 0%;
    width: 100%;
  }
}

/* Center the auth container */
.auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
}

/* Style the heading */
.auth-box h2 {
  margin-bottom: 20px;
}

/* Style the form groups */
.auth-form-group {
  margin-bottom: 1%;
  text-align: left;
}

/* Style the form labels */
.auth-form-group label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}

/* Style the form inputs */
.auth-form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

/* Style the auth button */
.auth-button {
  width: 100%;
  padding: 10px;
  background-color: var(--primary-background-color);
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
  margin-bottom: 5px;
}

/* Hover effect for the auth button */
.auth-button:hover {
  background-color: var(--button-hover-color);
}

/* Style the error message */
.error-message {
  color: var(--danger-color);
  margin-bottom: 20px;
}

.success-message {
  color: var(--secondary-background-color);
  margin-bottom: 20px;
}

/* Style the register link */
.auth-link {
  color: var(--primary-background-color);
  text-decoration: none;
  font-weight: bold;
}

.auth-link:hover {
  text-decoration: underline;
}

/* Shake animation */
@keyframes shake {

  0%,
  100% {
    transform: translateX(0);
  }

  8.3% {
    transform: translateX(-8px);
  }

  16.6% {
    transform: translateX(8px);
  }

  25% {
    transform: translateX(-6px);
  }

  33.3% {
    transform: translateX(6px);
  }

  41.6% {
    transform: translateX(-4px);
  }

  50% {
    transform: translateX(4px);
  }

  58.3% {
    transform: translateX(-2px);
  }

  66.6% {
    transform: translateX(2px);
  }

  75% {
    transform: translateX(-1px);
  }

  83.3% {
    transform: translateX(1px);
  }

  91.6% {
    transform: translateX(-0.5px);
  }
}

.shake {
  animation: shake 1s;
  /* Increased duration for smoother shake */
}