.pickup-details-page {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: left;
  font-family: var(--font-family);
  background-color: var(--blank-color);
}

@media (max-width: 600px) {
  .pickup-details-page {
    padding: 20px 10px 20px 10px;
  }
}

.pickup-page-split {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  text-align: center;
  align-items: center;
}

.location-images-section {
  align-items: center;
  text-align: center;
  width: 100%;
}

.pickup-content {
  text-align: left;
  width: 100%;
  max-width: 800px;
}

.pickup-content ul {
  list-style-type: none;
  padding: 0;
}

.pickup-content li {
  margin-bottom: 10px;
}

.pickup-content strong {
  font-weight: bold;
}
