/* Navbar.css */

.navbar {
  background-color: var(--primary-background-color);
  color: white;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  /* Ensure the dropdown is positioned correctly */
  font-family: var(--font-family);
}

.navbar h1 {
  margin: 0;
  padding-left: 0px;
}

.navbar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.navbar ul li {
  margin-right: 20px;
}

.navbar ul li a,
.navbar ul li .nav-link,
.navbar h2 .nav-link {
  color: white;
  text-decoration: none;
  transition: color 0.3s;
}

.navbar ul li a:hover,
.navbar ul li .nav-link:hover,
.navbar h2 .nav-link:hover {
  color: var(--button-hover-color);
}

.navbar ul li .nav-link,
.navbar h1 .nav-link {
  cursor: pointer;
}

.navbar ul li.logout {
  cursor: pointer;
}

.navbar ul li.logout:hover {
  color: var(--button-hover-color);
}

/* Profile dropdown styles */
.profile-dropdown-container {
  position: relative;
}

.profile-name {
  cursor: pointer;
}

.arrow {
  margin-left: 5px;
}

.profile-dropdown {
  position: absolute;
  right: 0;
  background-color: var(--primary-background-color);
  padding: 10px;
  border-radius: 2px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
  text-align: left;
  z-index: 99999;
}

.profile-dropdown .nav-link {
  color: white;
}

.profile-dropdown .nav-link:hover {
  color: var(--button-hover-color);
}

/* Media query for small screens */
@media (max-width: 900px) {
  .navbar ul {
    flex-direction: column;
    display: none;
    /* Hide the nav links */
    text-align: right;
    width: 100%;
  }

  .navbar ul.open {
    display: flex;
    /* Show the nav links when dropdown is open */
    text-align: right;
  }

  .navbar ul li {
    margin-right: 0;
    text-align: center;
    width: 100%;
    text-align: right;
  }

  .dropdown-icon {
    display: block;
    cursor: pointer;
    scale: 2;
    justify-content: center;
    margin-bottom: 7px;
  }
}

/* Media query for larger screens */
@media (min-width: 900px) {
  .dropdown-icon {
    display: none;
    /* Hide the dropdown icon */
  }
}

.navbar-logo-title {
  display: flex;
  align-items: center;
}

.navbar-logo {
  height: 3rem;
  /* Adjust the height to match the text height */
  margin-right: 0.5rem;
  /* Add some space between the logo and the title */
}

.navbar-title {
  margin-top: 10px;
}