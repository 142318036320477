.inventory-page {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--blank-color);
}

@media (max-width: 600px) {
  .inventory-page {
    padding: 20px 10px 20px 10px;
  }
}

.inventory-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 10px;
  width: 100%;
  /* Ensure the grid takes up the full width of the container */
  padding: 50px;
  padding-top: 0px;
  box-sizing: border-box;
  align-items: center;
  /* Include padding in the element's total width and height */
}

@media (max-width: 600px) {
  .inventory-grid {
    padding: 0px;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
}

.inventory-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-family: var(--font-family);
}

.inventory-title-container h3 {
  margin-left: 0px;
  text-align: left;
  margin-right: 30px;
  font-family: var(--font-family);
}

.title-buttons {
  display: flex;
  gap: 5px;
}

.add-item-button,
.select-button,
.action-button,
.confirm-button,
.add-auction-button {
  display: inline-block;
  padding: 8px 16px;
  border: none;
  border-radius: 2px;
  background-color: var(--primary-background-color);
  color: white;
  font-size: 13px;
  cursor: pointer;
  text-decoration: none;
  white-space: nowrap;
  /* Prevent text from wrapping */
}

.add-item-button:hover,
.select-button:hover,
.action-button:hover,
.confirm-button:hover,
.add-auction-button:hover {
  background-color: var(--button-hover-color);
  /* Darker shade of blue on hover */
}

.title-divider {
  width: 100%;
  border: none;
  border-top: 1.5px solid #000000;
  margin: 5px 0px 10px 0px;
}

.action-buttons {
  display: flex;
  gap: 5px;
  margin-bottom: 45px;
}

.auction-dropdown {
  margin-bottom: 20px;
}

.auction-dropdown select {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-right: 10px;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.page-button {
  margin: 0 5px;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 20px;
}

.page-button.active {
  background-color: var(--foreground-color);
}

.page-button:hover {
  background-color: var(--button-hover-color);
}