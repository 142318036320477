/* Carousel.css */

.carousel-container {
  width: 100%;
  /* Full width of the container */
  max-width: 100%;
  /* Adjust the max-width as needed */
  margin: 0;
  /* max-height: 300px; */
  /* Center the carousel */
  padding: 10px;
  background-color: var(--blank-color);
  justify-content: center;
  align-content: center;
  align-items: center;
  overflow: hidden;
  max-width: minmax(250px, 1fr);
}

.carousel-item-content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
  padding: 0 8%;
  width: 100%;
  max-height: 400px;
  /* Ensure this matches the max-height you want */
  overflow: hidden;
  /* Prevent overflowing */
  flex-wrap: wrap;
  /* Allow items to wrap if necessary */
}

.carousel-item-content>* {
  flex: 1;
  justify-content: center;
  max-width: 100%;
  /* Ensure the child containers don’t exceed the carousel width */
}

.carousel-item-image-container {
  display: flex;
  flex-direction: column;
  height: 300px;
  align-items: center;
  justify-content: center;
  object-fit: contain;
  margin-bottom: 10px;
  margin-top: 10px;
}

.carousel-item img {
  /* margin: 10px; */
  max-height: 300px;
  max-width: 300px;
  width: auto;
  object-fit: contain;
  align-items: center;
  justify-content: center;
}

.single-image-container {
  text-align: center;
  max-height: 300px;
  /* padding: 2% 0%; */
  /* Match the padding of the carousel */
  margin-bottom: 10px;
  margin-top: 10px;
}

.single-image-container img {
  max-height: 300px;
  max-width: 400px;
  width: auto;
  object-fit: contain;
  border-radius: 3px;
  margin: 0 auto;
  display: block;
}