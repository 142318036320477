.view-item-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 10px 20px 10px;
  background-color: white;
  box-sizing: border-box;
}

.item-details {
  width: 100%;
  background-image: linear-gradient(
    to right,
    var(--inventory-item-container-color),
    var(--highlight-color)
  );
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  max-width: 600px;
  text-align: center;
  align-self: center;
}

.item-details h2 {
  margin-bottom: 10px;
  font-size: 1.5em;
}

.view-item-price-container {
  margin-bottom: 10px;
  font-size: 1em;
}

.item-price-line {
  color: black;
}

.view-item-price {
  animation: glowPrice 1s ease-in-out infinite alternate;
}

@keyframes glowPrice {
  from {
    color: black;
  }

  to {
    color: var(--danger-color);
  }
}

.details-section {
  text-align: left;
  margin-bottom: 20px;
  font-size: 0.9em;
}

.details-section div {
  margin-bottom: 10px;
}

.details-section strong {
  font-weight: bold;
}

.images-section {
  background-image: linear-gradient(
    to right,
    var(--inventory-item-container-color),
    var(--highlight-color)
  );
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}

.item-image {
  max-width: 100%;
  max-height: 300px;
  margin: 10px;
}

.delete-button {
  background-color: var(--danger-color);
  display: inline-block;
  padding: 8px 16px;
  border: none;
  border-radius: 2px;
  color: white;
  font-size: 13px;
  cursor: pointer;
  text-decoration: none;
  white-space: nowrap;
}

.delete-button:hover {
  background-color: var(--button-hover-color);
}

.login-bid-button {
  background-color: var(--primary-background-color);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 13px;
}

.login-bid-button:hover {
  background-color: var(--button-hover-color);
}

.bid-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.bid-modal-content {
  position: relative;
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 300px;
}

.loading-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 4px;
  background-color: #007bff;
  border-radius: 8px 8px 0 0;
  animation: loading 2s forwards;
}

@keyframes loading {
  0% {
    width: 0;
  }

  50% {
    width: 100%;
  }

  100% {
    width: 100%;
  }
}

.bid-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.bid-button {
  background-color: var(--primary-background-color);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.bid-button:hover {
  background-color: var(--button-hover-color);
}

.bid-button:disabled {
  background-color: rgba(128, 128, 128, 0.5);
}

.close-modal-button {
  background-color: gray;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-left: 10px;
}

.close-modal-button:hover {
  background-color: var(--button-hover-color);
}

.winning-losing-highlight {
  background-color: var;
  border-radius: 8px;
  font-style: italic;
  margin-bottom: 10px;
}

.winning-losing-highlight.winning {
  color: var(--strong-highlight-color);
  animation: glowGreenStatus 1s ease-in-out infinite alternate;
}

.winning-losing-highlight.losing {
  color: var(--danger-color);
  animation: glowRedStatus 1s ease-in-out infinite alternate;
}

.winning-losing-highlight.won {
  color: var(--primary-background-color);
}

@keyframes glowRedStatus {
  from {
    color: black;
  }

  to {
    color: var(--danger-color);
  }
}

@keyframes glowGreenStatus {
  from {
    color: black;
  }

  to {
    color: var(--strong-highlight-color);
  }
}

.bid-summary {
  text-align: left;
}

.warning-message-highlighted {
  background-color: rgb(243, 205, 125);
  padding: 5px;
  border-radius: 3px;
  margin-top: 10px;
  text-align: center;
}

.price-summary-line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.error-message {
  color: var(--danger-color);
  margin-bottom: 20px;
}

@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }

  8.3% {
    transform: translateX(-8px);
  }

  16.6% {
    transform: translateX(8px);
  }

  25% {
    transform: translateX(-6px);
  }

  33.3% {
    transform: translateX(6px);
  }

  41.6% {
    transform: translateX(-4px);
  }

  50% {
    transform: translateX(4px);
  }

  58.3% {
    transform: translateX(-2px);
  }

  66.6% {
    transform: translateX(2px);
  }

  75% {
    transform: translateX(-1px);
  }

  83.3% {
    transform: translateX(1px);
  }

  91.6% {
    transform: translateX(-0.5px);
  }
}

.shake {
  animation: shake 1s;
}

.back-button-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  padding: 15px;
  padding-bottom: 30px;
}

.back-button {
  color: black;
  width: 100px;
  font-size: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.back-button:hover {
  color: #ffd4b1;
}

.to-auction-button {
  color: black;
  width: 100px;
  font-size: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.to-auction-button:hover {
  color: #ffd4b1;
}

.image-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
}

.image-modal p {
  color: white;
}

.image-modal-content {
  position: relative;
}

.enlarged-image {
  margin-top: 5vh;
  max-width: 90vw;
  max-height: 80vh;
  border: 2px solid white;
  border-radius: 4px;
}

.view-item-countdown-timer-container {
  margin-top: 20px;
}

.view-item-buttons-outer-container {
  display: flex;
  justify-content: center;
}

.view-item-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  align-self: center;
  width: 50%;
}
