.view-account-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  background-color: var(--blank-color);
}

@media (max-width: 600px) {
  .view-account-page {
    padding: 20px 10px 20px 10px;
  }
}

.account-details {
  width: 100%;
  background-color: var(--foreground-color);
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 600px;
  text-align: center;
  align-self: center;
  margin-top: 20px;
  font-family: var(--font-family);
}

.generic-rect-button {
  display: inline-block;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: var(--foreground-color);
  color: white;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
  margin: 5px;
}

.generic-rect-button:hover {
  background-color: var(--button-hover-color);
}