.inventory-item-container {
  background-image: linear-gradient(
    to right,
    var(--inventory-item-container-color),
    var(--highlight-color)
  );
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  transition: transform 0.1s;
  width: 100%;
  /* box-shadow: var(--inventory-item-container-color) 0 0 5px 5px; */
}

.inventory-item-container.selected {
  border: 2px solid var(--primary-background-color);
}

.inventory-item-container:hover {
  transform: scale(1.02);
}

.inventory-item-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
}

.item-container-details {
  text-align: left;
  width: 100%;
  height: 3.5lh;
  padding: 10px;
  padding-top: 0px;
  /* background-image: linear-gradient(to bottom right, red, yellow); */
  box-sizing: border-box;
  /* Include padding and border in size */
  font-family: var(--font-family);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.item-container-details h2 {
  height: 2lh;
  font-size: 0.8em;
  margin: 10px 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* Limit to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  hyphens: auto;
  word-break: break-word;
}

.item-title-container {
  height: 2lh;
}

.item-container-details span {
  font-size: 0.8em;
  margin: 5px 0;
  text-align: left;
}

.divider-line {
  width: 100%;
  height: 0px;
  /* background-color: #ffffff; */
  margin: 0 auto;
  margin-top: 5px;
}

.item-link {
  color: inherit;
  text-decoration: none;
  align-items: center;
  /* background-image: linear-gradient(to bottom right, red, yellow); */
  width: 100%;
  height: 100%;
  padding-bottom: 0px;
}

.item-content {
  color: inherit;
  text-decoration: none;
  align-items: center;
  /* background-image: linear-gradient(to bottom right, red, yellow); */
  width: 100%;
  height: 100%;
}

.item-price-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: var(--primary-background-color);
}

.action-buttons {
  display: flex;
  gap: 6px;
  margin-bottom: 20px;
}

/* Loading Styles */
.inventory-item-container.loading {
  /* background-image: linear-gradient(to bottom right, red, yellow); */
  align-items: center;
}

.loading-placeholder {
  background-color: var(--foreground-color);
}

.loading-placeholder.loading-text {
  width: 80%;
  height: 20px;
  margin: 10px 0;
  margin-left: 20px;
}

.loading-placeholder.loading-price {
  width: 50%;
  height: 20px;
  margin: 10px 0;
  margin-left: 20px;
}

.main-image {
  width: 100%;
  min-height: 333px;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: contain;
}

.main-image.container {
  position: relative;
  width: 100%;
  padding-top: 133.33%;
  /* 4:3 aspect ratio */
  opacity: 100;
  overflow: hidden;
}

.inventory-item-content-container.losing {
  animation: glowRedOutline 1s ease-in-out infinite alternate;
}

@keyframes glowRedOutline {
  from {
    border: 2px solid rgba(0, 0, 0, 0);
  }

  to {
    border: 2px solid var(--danger-color);
  }
}

.inventory-item-content-container.winning {
  animation: glowGreenOutline 1s ease-in-out infinite alternate;
}

.inventory-item-content-container.won {
  border: 2px solid var(--secondary-background-color);
}

@keyframes glowGreenOutline {
  from {
    border: 2px solid rgba(0, 0, 0, 0);
  }

  to {
    border: 2px solid var(--strong-highlight-color);
  }
}

.main-image.loading-placeholder {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: contain;
}

@media (max-width: 600px) {
  .main-image {
    min-height: 200px;
  }

  .main-image.loading-placeholder {
    min-height: 62vw;
    height: 62vw;
  }

  .inventory-item-container:hover {
    transform: scale(1);
  }
}

.item-status-container {
  font-style: italic;
}

.item-status-container.winning {
  animation: glowGreenStatus 1s ease-in-out infinite alternate;
}

.item-status-container.losing {
  animation: glowRedStatus 1s ease-in-out infinite alternate;
}

@keyframes glowRedStatus {
  from {
    color: black;
  }

  to {
    color: var(--danger-color);
  }
}

@keyframes glowGreenStatus {
  from {
    color: black;
  }

  to {
    color: var(--strong-highlight-color);
  }
}
