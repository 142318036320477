.footer {
  padding: 20px;
  text-align: center;
  font-family: var(--font-family);
  background-color: var(--primary-background-color);
}

.footer-content p {
  margin: 5px 0;
  color: var(--blank-color);
  font-size: 0.85rem;
}