.auctions-page {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--blank-color);
}

@media (max-width: 600px) {
  .auctions-page {
    padding: 20px 10px 20px 10px;
  }
}

.auctions-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  width: 100%;
  max-width: 1000px;
  /* Optional: set a max-width to control the container width */
  font-family: var(--font-family);
}

.auction-container {
  background-image: linear-gradient(to top right, var(--primary-background-color), var(--secondary-background-color));
  padding: 5px;
  margin: 0px;
  width: 100%;
  transition: transform 0.1s ease;
  border-radius: 3px;
  color: white;
}

@media (min-width: 600px) {
  .auction-container:hover {
    transform: scale(1.01);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
}

.auction-link {
  text-decoration: none;
  color: inherit;
}

.auction-link h3 {
  padding-top: 10px;
  font-size: 1.4rem;
}

.auction-link p {
  font-size: 0.9rem;
}

@media (max-width: 600px) {
  .auction-link h3 {
    font-size: 1.2rem;
  }

  .auction-link p {
    font-size: 0.8rem;
  }
}