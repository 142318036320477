.view-auction-page {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: var(--font-family);
  background-color: var(--blank-color);
}

@media (max-width: 600px) {
  .view-auction-page {
    padding: 20px 10px 20px 10px;
  }
}

.auction-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.auction-title-container h1 {
  margin: 0;
}

.title-buttons {
  display: flex;
  gap: 5px;
}

.search-layer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.search-input-container {
  position: relative;
  width: 100%;
}

@media (min-width: 600px) {
  /* NOTE: This is for desktop */

  .search-input-container {
    max-width: 400px;
    margin-left: 50px;
  }


}

.search-input {
  font-size: 14px;
  height: 40px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 0px;
  padding: 10px 40px 10px 10px;
  /* Add right padding for the icon */
  margin-left: 0px;
  margin-right: 10px;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.search-icon {
  -moz-transform: scale(1, -1);
  -webkit-transform: scale(1, -1);
  -o-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  transform: scale(-1.4, 1.4) translateY(-35%);
  position: absolute;
  right: 15px;
  /* Adjust the position */
  top: 23px;
  cursor: pointer;
  font-family: "Montserrat";
  /* Use Material Icons font */
  font-size: 24px;
  /* Adjust the size as needed */
}

.empty-auction-message {
  text-align: center;
  font-size: 1.2em;
  color: #888;
  margin-top: 20px;
}

.delete-auction-button {
  padding: 8px 16px;
  border: none;
  border-radius: 2px;
  background-color: var(--danger-color);
  color: white;
  cursor: pointer;
  font-size: 13px;
}

.delete-auction-button:hover {
  background-color: var(--button-hover-color);
}

.pagination {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 40px;
}

@media (min-width: 600px) {
  .pagination {
    margin-right: 50px;
  }
}

.custom-select {
  position: relative;
  display: inline-block;
  margin: 0 5px;
}

.custom-select .selected-page,
.custom-select .arrow {
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
}

.custom-select .arrow {
  margin-left: 5px;
}

.custom-select select.page-select {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}