.add-auction-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: var(--blank-color);
}

.details-section {
  background-color: var(--foreground-color);
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 600px;
  width: 100%;
  margin-bottom: 20px;
  font-family: var(--font-family);
}

label {
  display: block;
  margin-bottom: 10px;
}

input[type="text"],
input[type="datetime-local"],
textarea,
select {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 20px;
  border-radius: 4px;
  border: 1px solid #ccc;
}