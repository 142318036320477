/* Highlight Section */
.highlight-container {
  display: flex;
  flex-direction: column;
  margin: 2% 5%;
  padding: 1%;
  background-color: var(--blank-color);
  border-radius: 10px;
  object-fit: contain;
}

.info-container {
  display: flex;
  flex-direction: row;
  background-image: linear-gradient(to top right, var(--inventory-item-container-color), var(--secondary-background-color));
  gap: 30px;
  padding: 20px;
  min-height: 300px;
  /* Space between map and pickup info */
}


.location-container {
  background-image: url('../../Assets/Location.png');
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
  background-color: var(--primary-background-color);
  color: var(--blank-color);
  /* Use theme colors */
}

.location-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.845);
  /* Dark overlay */
  border: none;
  z-index: 1;
}

.location-content {
  margin: 50px 20px 50px 20px;
  position: relative;
  z-index: 2;
}

.location-content h2 {
  font-size: 2rem;
  font-weight: bold;
}

.location-content p {
  font-size: 0.9rem;
  margin-top: 10px;
  padding-right: 20%;
  padding-left: 20%;
}

@media (max-aspect-ratio: 1/1) {
  .location-content p {
    padding-right: 10px;
    padding-left: 10px;
  }
}


.pickup-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--blank-color);
  padding: 10px;
  border-radius: 3px;
  margin-top: 40px;
}

.pickup-time-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-right: 10%;
  padding-left: 10%;
}

.pickup-time-container-pickup-page {
  margin-top: 10px;
  width: 300px;
  min-width: 250px;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: left;
}

.pickup-time-days,
.pickup-time-hours {
  text-align: left;
  line-height: 1.5;
}

.intro-links {
  text-decoration: none;
  color: var(--primary-background-color);
  font-weight: bold;
}

/* Hero Section */
.hero {
  background-image: url('../../Assets/ImageCollage.png');
  background-size: cover;
  background-position: center;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
  background-color: var(--primary-background-color);
  color: var(--blank-color);
  border-bottom: solid var(--secondary-background-color);
  /* Use theme colors */
}

@media (min-width: 600px) {
  .hero {
    height: 500px;
  }
}

.hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.768);
  /* Dark overlay */
  border: none;
  z-index: 1;
}

.hero-content {
  position: relative;
  z-index: 2;
}

.hero-content h1 {
  font-size: 3rem;
  font-weight: bold;
}

.hero-content p {
  font-size: 1.2rem;
  margin-top: 10px;
}

.buttons {
  margin-top: 10px;
}


.hero-button {
  display: inline-block;
  padding: 8px 16px;
  border: none;
  border-radius: 2px;
  background-image: linear-gradient(to top right, var(--primary-background-color), var(--secondary-background-color));
  color: white;
  font-size: 13px;
  cursor: pointer;
  text-decoration: none;
  white-space: nowrap;
  /* Prevent text from wrapping */
  margin-right: 10px;
  margin-left: 10px;
}

.hero-button.secondary {
  background-image: linear-gradient(to top right, var(--primary-background-color), var(--secondary-background-color));
}

.hero-button:hover {
  background-image: linear-gradient(to right, var(--button-hover-color), var(--button-hover-color));
}

/* Popular Items Section */
.popular-items-container {
  padding: 40px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--blank-color);
  border-bottom: solid var(--secondary-background-color);
}

.section-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: var(--primary-background-color);
}

/* Mini Inventory Grid for Popular Items */
.mini-inventory-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  max-width: 900px;
  /* Ensure max-width to center items */
  gap: 20px;
  justify-items: center;
  width: 100%;
}

.mini-inventory-grid p {
  text-align: center;
}

@media (min-width: 980px) {

  .mini-inventory-grid {
    grid-template-columns: repeat(3, 1fr);
    /* Max 3 items per row */
  }
}

@media (max-width: 980px) {

  /* For smaller screens */
  .mini-inventory-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    /* 2 items per row on mobile */
  }
}