.add-item-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 10px 20px 10px;
  background-color: var(--blank-color);
}

/* Ensure the details section is positioned relative to contain the loading bar */
.details-section {
  background-image: linear-gradient(to right, var(--inventory-item-container-color), var(--highlight-color));
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 600px;
  width: 100%;
  margin-bottom: 20px;
  font-size: 1em;
}

.details-section label {
  text-align: left;
}

/* Loading bar styles */
.loading-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: var(--secondary-background-color);
  z-index: 9999;
  /* Ensure the loading bar stays above other content */
  animation: loading 2s forwards;
  /* Run once */
}

@keyframes loading {
  0% {
    left: 0%;
    width: 0%;
  }

  100% {
    left: 0%;
    width: 100%;
  }
}

.image-upload-section {
  background-image: linear-gradient(to right, var(--inventory-item-container-color), var(--highlight-color));
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 600px;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}

.title-container {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}

.images-preview {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.images-preview img {
  max-width: 100%;
  max-height: 300px;
  border-radius: 4px;
}

.image-container {
  position: relative;
  margin: 0.5rem;
  /* border: 2px solid transparent; */
  cursor: pointer;
  /* background-color: var(--secondary-background-color); */
}

.image-container.thumbnail {
  border: 4px solid var(--button-hover-color);
  border-radius: 10px;
  /* Highlight the selected thumbnail with blue border */
}

.image-preview {
  width: 200px;
  height: 100%;
  object-fit: cover;
}

.image-preview.acting-upon {
  background-color: var(--blank-color);
  opacity: 0.5;
  /* Add white opaqueness */
}

.image-container button {
  position: absolute;
  top: 0;
  right: 0;
  background: var(--danger-color);
  border: none;
  color: white;
  padding: 0.2rem 0.5rem;
  cursor: pointer;
}

.select-indicator,
.delete-indicator {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  padding: 0.2rem 0.5rem;
  font-size: 12px;
  border-radius: 2px;
}

.select-indicator {
  background-color: var(--foreground-color);
}

.delete-indicator {
  background-color: var(--danger-color);
}

.image-actions {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 10px;
}

label {
  display: block;
  margin-bottom: 10px;
}

input[type="text"],
input[type="number"],
textarea,
select {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 20px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

button[type="upload-button"] {
  background-color: var(--primary-background-color);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button[type="upload-button"]:hover {
  background-color: var(--button-hover-color);
}

.upload-button-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.upload-button {
  display: inline-block;
  background-color: var(--primary-background-color);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  position: relative;
  width: auto;
  height: auto;
}

.upload-button input {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
}

.upload-button:hover {
  background-color: var(--button-hover-color);
}

.upload-button span {
  display: block;
  font-size: 24px;
  line-height: 1;
  pointer-events: none;
}

.file-input {
  cursor: pointer;
}

/* Add this to AddItem.css */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--blank-color);
  opacity: 0.5;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-spinner {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Progress bar styles */
.images-progress-section {
  width: 100%;
  margin-top: 20px;
}

.images-progress-bar {
  display: flex;
  align-items: left;
  margin-bottom: 10px;
  width: 100%;
  background-color: var(--blank-color);
  border-radius: 4px;
  overflow: hidden;
}

.images-progress {
  height: 24px;
  background-color: var(--highlight-color);
  text-align: left;
  white-space: nowrap;
  color: white;
  padding: 5px;
  box-sizing: border-box;
}

.images-progress-label {
  margin-right: 10px;
  font-weight: bold;
}