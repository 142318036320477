.countdown-timer {
  font-size: 0.9rem;
  height: 1lh;
  font-weight: 500;
  width: 100%;
  color: var(--danger-color);
  margin: 0px;
  padding: 0px;
  text-align: center;
  font-family: var(--font-family);
}

.countdown-timer.start {
  color: var(--secondary-background-color);
}